import defaultProps from '@/entities/section/fixtures/headerDesktop/defaultProps';

export default class Header {
    preview: string;
    title: string;
    template: any;
    onScroll: 'sticky' | 'static';
    tags: string[];
    locate: string;
    constructor(args: {
        preview: string;
        title: string;
        template: any;
        onScroll: 'sticky' | 'static';
        tags: string[];
        locate: string;
    }) {
        this.preview = args.preview || require(`@/assets/images/headers-desktop/${args.title}.png`);
        this.title = args.title;
        this.template = [];
        this.onScroll = args.onScroll || 'sticky';
        this.tags = [];
        this.locate = args.locate;
        this.createTagsAndTemplate(args.template);
    }

    static defaultProps: any = defaultProps;

    createTagsAndTemplate(template: { [key: string]: any }) {
        const itemsToIncludeAsTags: { [key: string]: string } = {
            socials: 'Socials',
            text: 'Text',
            search: 'Search',
            phone: 'Phone',
            cart: 'Cart',
            menu: 'Menu',
            account: 'Account',
            socialsFacebook: 'Socials',
            socialsInstagram: 'Socials',
            socialsX: 'Socials',
            socialsLinkedIn: 'Socials',
            socialsMedium: 'Socials',
            socialsTelegram: 'Socials',
            socialsTikTok: 'Socials',
            socialsVK: 'Socials',
            buttonPhone: 'Phone button',
            buttonPopup: 'Popup button',
            buttonLink: 'Button with link',
            buttonWhatsapp: 'Whatsapp button',
            email: 'Email',
            whatsapp: 'Socials',
            telegram: 'Socials',
        };
        template.forEach((level: any) => {
            const newTemplateItem = {
                ...level,
                items: [],
            };
            level.items.forEach((item: any) => {
                let defaultItem;
                if (typeof item !== 'object') {
                    defaultItem = Header.defaultProps.components[item];
                } else if (item !== null) {
                    defaultItem = Header.defaultProps.components[item.component](item.props);
                } else {
                    defaultItem = { component: null };
                }
                const tag =
                    typeof item !== 'object' ? itemsToIncludeAsTags[item] : itemsToIncludeAsTags[item?.component];
                if (tag && !this.tags.includes(tag)) {
                    this.tags.push(itemsToIncludeAsTags[item]);
                }
                newTemplateItem.items.push(defaultItem);
            });
            this.template.push(newTemplateItem);
        });
    }

    static stylesPath = { path: ['styles'], scrollTo: ['header', 'desktop'] };

    get data() {
        return {
            preview: this.preview,
            title: this.title,
            tags: this.tags,
            onScroll: this.onScroll,
            locate: this.locate,
            template: this.template,
        };
    }
}
