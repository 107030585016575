import icons from '@/entities/icons';
import store from '@/store/store';
const components = {
    whatsapp: { component: 'Whatsapp', props: { text: 'text' } },
    whatsappIconTitle: { component: 'Whatsapp', props: { text: 'title' } },
    whatsappIconOnly: { component: 'Whatsapp', props: { text: null } },
    logo: { component: 'Logo', props: { height: 54 } },
    infoMenu: { component: 'InfoMenu' },
    mainMenu: { component: 'MainMenu' },
    workTime: { component: 'WorkTime' },
    telegram: { component: 'Telegram', props: { text: 'title' } },
    telegramIconOnly: { component: 'Telegram', props: { text: null } },
    whatsapps: {
        component: 'Whatsapps',
        props: {
            icon: 'icon',
        },
    },
    whatsappsTextLeft: {
        component: 'Whatsapps',
        props: {
            icon: 'textLeft',
        },
    },
    whatsappsTextTop: {
        component: 'Whatsapps',
        props: {
            icon: 'textTop',
        },
    },
    emails: {
        component: 'Emails',
        props: {
            icon: 'icon',
        },
    },
    emailsTextLeft: {
        component: 'Emails',
        props: {
            icon: 'textLeft',
        },
    },
    emailsTextTop: {
        component: 'Emails',
        props: {
            icon: 'textTop',
        },
    },
    search: {
        component: 'Search',
    },
    socials: {
        component: 'Socials',
    },

    phone: { component: 'Phone', props: { icon: 'icon', text: 'text' } },
    phoneIconOnly: { component: 'Phone', props: { icon: 'icon', text: null } },
    phoneTextOnly: { component: 'Phone', props: { icon: null, text: 'text' } },
    phones: { component: 'Phones', props: { icon: 'icon' } },
    phonesTextLeft: { component: 'Phones', props: { icon: 'textLeft' } },
    phonesTextTop: { component: 'Phones', props: { icon: 'textTop' } },
    email: { component: 'Email', props: { icon: 'icon', text: 'text' } },
    emailIconOnly: { component: 'Email', props: { icon: 'icon', text: null } },
    emailTextOnly: { component: 'Email', props: { icon: null, text: 'text' } },
    cart: { component: 'Cart', props: { text: 'text' } },
    cartIconOnly: { component: 'Cart', props: { text: null } },
    address: { component: 'Address' },
    account: { component: 'Account', props: { icon: 'icon', text: 'text' } },
    accountIconOnly: { component: 'Account', props: { icon: 'icon', text: null } },
    accountTextOnly: { component: 'Account', props: { icon: null, text: 'text' } },
    buttonPopup: {
        component: 'Button',
        props: {
            button: {
                isShow: true,
                styles: 'primary',
                text: 'Book Now',
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'popup',
                url: null,
            },
        },
    },
    buttonHomepage: {
        component: 'Button',
        props: {
            button: {
                isShow: true,
                styles: 'text',
                text: 'Homepage',
                textColor: null,
                bgColor: null,
                link: '/',
                iconLeft: null,
                iconRight: null,
                type: 'link',
                url: { type: 'homepage', id: null, title: 'Homepage', url: '/' },
            },
        },
    },
    buttonPhone: {
        component: 'Button',
        props: {
            button: {
                isShow: true,
                styles: 'secondary',
                text: store.getters['builder/getSettings'].common.phones.value[0],
                value: store.getters['builder/getSettings'].common.phones.value[0],
                textColor: null,
                bgColor: null,
                link: null,
                iconLeft: null,
                iconRight: null,
                type: 'phone',
                url: null,
            },
        },
    },
    customText: {
        component: 'CustomText',
        props: { html: '<p>TextHere</p>' },
    },
    column: (props: any) => {
        return {
            component: 'Column',
            props: { items: props.items.map((item: string) => components[item] || null) },
        };
    },
} as { [key: string]: any };

const newStructureForHeaderItems = [
    {
        title: 'button',
        component: 'Select',
        templateComponent: 'Button',
        customization: {
            component: 'ButtonBuilder',
            button: {},
            valueName: 'button',
            default: { ...components.buttonPopup.props },
            props: {
                types: [
                    { text: 'Link', value: 'link' },
                    { text: 'Popup', value: 'popup' },
                    { text: 'Phone', value: 'phone' },
                    { text: 'Whatsapp', value: 'whatsapp' },
                ],
                icons: [...icons],
                typesAsSelect: true,
            },
        },
    },

    {
        title: 'logo',
        templateComponent: 'Logo',
        component: 'Select',
        customization: {
            component: 'InputNumberAndRange',
            valueName: 'height',
            default: { height: 54 },
            props: { min: 35, max: 120 },
        },
    },
    { title: 'infoMenu', component: 'Select', templateComponent: 'InfoMenu', customization: {} },
    { title: 'mainMenu', component: 'Select', templateComponent: 'MainMenu', customization: {} },
    { title: 'workTime', component: 'Select', templateComponent: 'WorkTime', customization: {}, useInColumn: true },
    {
        title: 'telegram',
        component: 'Select',
        templateComponent: 'Telegram',
        customization: {
            component: 'Select',
            default: { ...components.telegram.props },
            variants: {
                iconText: { ...components.telegram.props },
                iconOnly: { ...components.telegramIconOnly.props },
            },
            props: {},
        },
        useInColumn: true,
    },

    {
        title: 'whatsapp',
        component: 'Select',
        templateComponent: 'Whatsapp',
        customization: {
            component: 'Select',
            default: { ...components.whatsapp.props },
            variants: {
                iconText: { ...components.whatsapp.props },
                iconTitle: { ...components.whatsappIconTitle.props },
                iconOnly: { ...components.whatsappIconOnly.props },
            },
            props: {},
        },
        useInColumn: true,
    },

    {
        title: 'whatsapps',
        component: 'Select',
        templateComponent: 'Whatsapps',
        customization: {
            component: 'Select',
            default: { ...components.whatsapp.props },
            variants: {
                iconOnly: { ...components.whatsapps.props },
                iconAndTextLeft: { ...components.whatsappsTextLeft.props },
                iconAndTextTop: { ...components.whatsappsTextTop.props },
            },
            props: {},
        },
    },
    {
        title: 'emails',
        component: 'Select',
        templateComponent: 'Emails',
        customization: {
            component: 'Select',
            default: { ...components.emails.props },
            variants: {
                iconOnly: { ...components.emails.props },
                iconAndTextLeft: { ...components.emailsTextLeft.props },
                iconAndTextTop: { ...components.emailsTextTop.props },
            },
            props: {},
        },
    },
    {
        title: 'phone',
        component: 'Select',
        templateComponent: 'Phone',
        customization: {
            component: 'Select',
            default: { ...components.phone.props },
            variants: {
                iconText: { ...components.phone.props },
                iconOnly: { ...components.phoneIconOnly.props },
                textOnly: { ...components.phoneTextOnly.props },
            },
            props: {},
        },
        useInColumn: true,
    },

    {
        title: 'phones', // $t(builder.header.items.title)
        component: 'Select',
        templateComponent: 'Phones',
        customization: {
            default: { ...components.phones.props },
            component: 'Select',
            variants: {
                iconOnly: { ...components.phones.props },
                iconAndTextLeft: { ...components.phonesTextLeft.props },
                iconAndTextTop: { ...components.phonesTextTop.props },
            },
            props: {},
        },
    },
    {
        title: 'search', // $t(builder.header.items.title)
        component: 'Select',
        templateComponent: 'Search',
        customization: {},
    },
    {
        title: 'socials', // $t(builder.header.items.title)
        component: 'Select',
        templateComponent: 'Socials',
        customization: {},
    },
    {
        title: 'email', // $t(builder.header.items.title)
        component: 'Select',
        templateComponent: 'Email',
        customization: {
            component: 'Select',
            default: { ...components.email.props },
            variants: {
                iconText: { ...components.email.props },
                iconOnly: { ...components.emailIconOnly.props },
                textOnly: { ...components.emailTextOnly.props },
            },
            props: {},
        },
        useInColumn: true,
    },
    {
        title: 'address', // $t(builder.header.items.title)
        component: 'Select',
        templateComponent: 'Address',
        customization: {},
        useInColumn: true,
    },
    {
        title: 'cart', // $t(builder.header.items.title)
        component: 'Select',
        templateComponent: 'Cart',
        customization: {
            component: 'Select',
            default: { ...components.cart.props },
            variants: {
                iconText: { ...components.cart.props },
                iconOnly: { ...components.cartIconOnly.props },
            },
            props: {},
        },
    },
    {
        title: 'account', // $t(builder.header.items.title)
        templateComponent: 'Account',
        component: 'Select',
        customization: {
            component: 'Select',
            // при загрузке смотрим, совпадает ли value с одним из вариантов
            // если не совпадает, то ставим default
            // если совпадает, то и так понятно
            default: { ...components.account.props },
            variants: {
                // $t(builder.header.items.items.iconOnly)
                iconText: { ...components.account.props },
                iconOnly: { ...components.accountIconOnly.props },
                textOnly: { ...components.accountTextOnly.props },
            },
            props: {},
        },
    },
    {
        title: 'customText', // $t(builder.header.items.title)
        component: 'Select',
        templateComponent: 'CustomText',
        customization: {
            component: 'CodeEditor',
            valueName: 'html',
            default: { html: '' },
            props: {},
        },
    },
    {
        title: 'column', // $t(builder.header.items.title)
        component: 'Select',
        templateComponent: 'Column',
        customization: {
            component: 'template-builder-input-column',
            valueName: 'items',
            default: { items: [{ component: null }, { component: null }] },
            props: {},
        },
    },
];

export default (() => {
    // sections.push(SectionTestList);

    const getItemsComponents = () => newStructureForHeaderItems.map((item) => item.component);
    const getItemsForComponentSelect = () =>
        newStructureForHeaderItems
            .filter((item) => item.component === 'Select')
            .map((item) => {
                return { value: item.templateComponent, text: `settings.header.templateItems.${item.title}` };
            });
    const getItemsForColumnSelect = () =>
        newStructureForHeaderItems
            .filter((item) => item.useInColumn)
            .map((item) => {
                return { value: item.templateComponent, text: `settings.header.templateItems.${item.title}` };
            });
    const getItemByTemplateComponent = (component: string) => {
        return newStructureForHeaderItems.find((item) => item.templateComponent === component);
    };

    return Object.freeze({
        getItemsComponents,
        getItemsForComponentSelect,
        getItemByTemplateComponent,
        getItemsForColumnSelect,
        components,
    });
})();
